import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import GoogleIcon from "../icons/google-icon"
import ReactPaginate from "react-paginate"

class Reviews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            itemsPerPage: 4,
        }
    }

    handlePageChange = ({ selected }) => {
        this.setState({ currentPage: selected })
    }

    render() {
        const reviewData = this.props.data.allContentfulReviews.edges[0].node
        const { currentPage, itemsPerPage } = this.state
        const indexOfLastReview = (currentPage + 1) * itemsPerPage
        const indexOfFirstReview = indexOfLastReview - itemsPerPage
        const currentReviews = reviewData.reviews.slice(indexOfFirstReview, indexOfLastReview)
        return (
            <Layout>
                <Seo
                    title="Kaylar Construction Customer Reviews: Testimonials for Quality Construction Services"
                    description="Discover what our clients have to say about their experiences with Kaylar Construction. Our customer reviews page showcases testimonials highlighting our commitment to excellence 
                    in construction services, including remodeling, restoration, and more. Read firsthand accounts of our exceptional work and customer satisfaction, and see why we’re the trusted choice for your next project."
                    keywords={[
                        "Kaylar Construction reviews",
                        "customer testimonials Kaylar Construction",
                        "client feedback Kaylar Construction",
                        "construction service reviews",
                        "home renovation reviews",
                        "remodeling testimonials",
                        "customer satisfaction construction",
                        "Kaylar Construction testimonials",
                        "home improvement reviews",
                        "construction company feedback"
                    ]}
                    location={this.props.location.pathname}
                />
                <div className="bg-gray pt-40 lg:pt-48 pb-10 relative">
                    <div className="container mx-auto px-5 xl:px-[195px]">
                        <h2 className="text-sm text-dhred px-5">Google Reviews</h2>
                        <p className="px-5 font-bold text-2xl lg:text-3xl">Trusted By Hundreds Of Home Owners Each Year</p>
                        <p className="px-5 text-lg pt-5 pb-10">We prioritize ensuring that homeowners have an excellent experience when working with us. Ultimately, our satisfaction comes from successfully delivering the services that our clients seek. Take a look at our reviews below to see feedback from others.</p>
                        <div className="grid md:grid-cols-2 gap-y-8">
                            {currentReviews.map((review) => (
                                <div key={review.id} className="px-4 relative">
                                    <div className="bg-white p-6 rounded-lg shadow-md flex items-center" style={{ height: "400px" }}>
                                        <div>
                                            <p className="text-lg font-semibold">{review.author}</p>
                                            <p className="text-gray-500 text-sm mb-2">{review.date}</p>
                                            <div className="flex mb-2">
                                                {[...Array(review.rating)].map((_, index) => (
                                                    <svg key={index} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-400 fill-current mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                        <path d="M10 1l2.46 6.755L19 7.755l-5.612 4.628L15.888 19 10 15.875 4.112 19l1.5-6.617L1 7.755l6.54-.19L10 1z" />
                                                    </svg>
                                                ))}
                                            </div>
                                            <p className="text-gray-700">{review.review}</p>
                                        </div>
                                        <div className="absolute top-6 right-12 w-2 h-2" >
                                            <GoogleIcon />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-center pt-12">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"mt-1.5"}
                                pageCount={Math.ceil(reviewData.reviews.length / itemsPerPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageChange}
                                breakLinkClassName="px-1.5 py-1 rounded-full hover:rounded-full hover:bg-lightblue transition duration-150 ease-in-out hover:text-lightblue"
                                containerClassName="flex space-x-2"
                                activeLinkClassName="px-2 py-0.5 rounded-full bg-lightblue text-white"
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                pageClassName=""
                                pageLinkClassName="px-2 py-0.5 rounded-full hover:bg-lightblue hover:rounded-full hover:text-white transition duration-150 ease-in-out"
                                renderOnZeroPageCount={null}
                                nextClassName='hover:text-lightblue transition duration-150 ease-in-out'
                                previousClassName='hover:text-lightblue transition duration-150 ease-in-out'
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
export default Reviews
export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    allContentfulReviews {
        edges {
          node {
            reviews {
                id
                author
                date
                rating
                review
                avatar
              }
          }
        }
      }
}
`